import { h } from "preact";
import { Route, Router } from "preact-router";

// Code-splitting is automated for `routes` directory
import { DemoHandler } from "../routes/iframe/demo";
import {
  RheinEnergieGas,
  RheinEnergieStrom,
  RheinEnergieWaerme,
} from "../routes/iframe/rheinenergie";
import StromRoute from "../routes/iframe/stromsparrechner-v1";
import GasRoute from "../routes/iframe/v1";
import {
  EinsparrechnerGas,
  EinsparrechnerWaerme,
  EinsparrechnerStrom,
} from "../routes/iframe/v2";

const App = () => (
  <div id="app">
    <Router>
      <Route path="/demo" component={DemoHandler} />
      <Route path="/iframe/default" component={EinsparrechnerGas} />
      <Route path="/iframe/gas" component={EinsparrechnerGas} />
      <Route path="/iframe/waerme" component={EinsparrechnerWaerme} />
      <Route path="/iframe/strom" component={EinsparrechnerStrom} />
      <Route path="/gassparrechner/iframe/v1" component={GasRoute} />
      <Route path="/gassparrechner/iframe/v2" component={EinsparrechnerGas} />
      <Route path="/iframe/stromsparrechner" component={StromRoute} />
      <Route path="/stromsparrechner/iframe/v1" component={StromRoute} />
      <Route path="/iframe/rheinenergie-gas" component={RheinEnergieGas} />
      <Route path="/iframe/rheinenergie-strom" component={RheinEnergieStrom} />
      <Route
        path="/iframe/rheinenergie-waerme"
        component={RheinEnergieWaerme}
      />
      <Route path="/iframe/preisbremse-gas" component={RheinEnergieGas} />
      <Route path="/iframe/preisbremse-strom" component={RheinEnergieStrom} />
      <Route path="/iframe/preisbremse-waerme" component={RheinEnergieWaerme} />
    </Router>
  </div>
);

export default App;
