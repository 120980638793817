export interface IOpportunity {
  label: string;
  description: string;
  factor?: number;
  value?: number;
  scale?: boolean;
  scaleUnit?: string;
}

export type IOpportunities = Record<string, IOpportunity>;

export type IUnits = "strom" | "gas" | "waerme";

export function fixTexts(TEXT: any, type: IUnits): void {
  if (type === "strom") {
    replaceObjectValues(TEXT, "{{UNIT}}", "Strom");

    TEXT.state_funding_monthly_tooltip =
      TEXT.state_funding_monthly_tooltip__strom;
  }

  if (type === "gas") {
    replaceObjectValues(TEXT, "{{UNIT}}", "Gas");

    TEXT.state_funding_monthly_tooltip =
      TEXT.state_funding_monthly_tooltip__gas;
  }

  if (type === "waerme") {
    replaceObjectValues(TEXT, "{{UNIT}}", "Wärme");

    TEXT.state_funding_monthly_tooltip =
      TEXT.state_funding_monthly_tooltip__waerme;
  }

  for (const key of Object.keys(TEXT)) {
    if (key.endsWith("__" + type)) {
      const otherKey = key.replace("__" + type, "");

      TEXT[otherKey] = TEXT[key];
    }
  }
}

export function replaceObjectValues(
  obj: Record<string, string>,
  from: string,
  to: string
) {
  for (const key of Object.keys(obj)) {
    if (typeof obj[key] === "string") {
      obj[key] = obj[key].replaceAll(from, to);
    }
  }
}

export function calcMonthlyStateFunding(
  type: "strom" | "gas" | "waerme",
  consumtion: number,
  workingPrice: number
): number {
  switch (type) {
    case "gas":
      if (consumtion > 1_500_000) {
        return (Math.max(0, workingPrice - 0.07) * (consumtion * 0.7)) / 12;
      } else {
        return (Math.max(0, workingPrice - 0.12) * (consumtion * 0.8)) / 12;
      }

    case "waerme":
      if (consumtion > 1_500_000) {
        return (Math.max(0, workingPrice - 0.075) * (consumtion * 0.7)) / 12;
      } else {
        return (Math.max(0, workingPrice - 0.095) * (consumtion * 0.8)) / 12;
      }

    case "strom":
    default:
      if (consumtion > 30_000) {
        return (Math.max(0, workingPrice - 0.13) * (consumtion * 0.7)) / 12;
      } else {
        return (Math.max(0, workingPrice - 0.4) * (consumtion * 0.8)) / 12;
      }
  }
}
