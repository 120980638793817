import { Fragment, h } from "preact";
import { useState } from "preact/hooks";

import { TEXT } from "../const";

export function Cards({
  warning,
  step,
  steps,
  onStep,
  nextDisabled,
  children,
}: {
  step: number;
  onStep: (step: number) => void;
  steps: {
    title: string;
    titleShort: string;
    description: string;
    descriptionBeforeTitle: boolean;
  }[];
  warning?: string;
  nextDisabled?: boolean;

  children: any;
}) {
  const onNext = step < steps.length - 1 ? () => onStep(step + 1) : undefined;
  const onBack = step > 0 ? () => onStep(step - 1) : undefined;

  const title = steps[step].title;
  const description = steps[step].description;
  const descriptionBeforeTitle = steps[step].descriptionBeforeTitle;

  return (
    <div class="cards">
      {steps.length > 1 && (
        <div class="cards-head">
          {steps.map((s, i) => {
            const active = i === step;
            const done = i < step;
            const disabled = nextDisabled && i > step;

            const className = disabled
              ? "cards-step disabled"
              : active
              ? "cards-step active"
              : done
              ? "cards-step done"
              : "cards-step";

            return (
              <div
                class={className}
                onClick={() => {
                  if (!disabled) {
                    onStep(i);
                  }
                }}
              >
                <div class="cards-step-number">{done ? "✓" : i + 1}</div>
                <div class="cards-step-title">{s.titleShort}</div>
              </div>
            );
          })}
        </div>
      )}
      <div class="cards-content">
        <div class="card">
          {warning && <div class="card-warning">{warning}</div>}
          <div class="card-header">
            {descriptionBeforeTitle && (
              <div class="card-description">
                <div dangerouslySetInnerHTML={{ __html: description }} />
                <br></br>
                <br></br>
              </div>
            )}

            {!!title && <div class="card-title">{title}</div>}

            {!!description && !descriptionBeforeTitle && (
              <div class="card-description">{description}</div>
            )}
          </div>
          <div class="card-content">{children}</div>
          <div class="card-footer">
            <div class="card-footer-child">
              {onBack && (
                <button
                  class="button-outline"
                  onClick={onBack}
                  children={TEXT.back}
                />
              )}
            </div>
            <div class="card-footer-child">
              {onNext && (
                <button
                  disabled={nextDisabled}
                  onClick={onNext}
                  children={TEXT.next}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function Row({
  label,
  tooltip,
  content = "",
  title,
  primary,
  multiline,
  contentClass = "",
}: {
  label: string;
  tooltip?: string;
  content?: string;
  title?: boolean;
  primary?: boolean;
  multiline?: boolean;
  contentClass?: string;
}) {
  let className = "row";

  if (title) {
    className += " row-title";
  }

  if (primary) {
    className += " row-primary";
  }

  if (multiline) {
    className += " row-multiline";
  }

  return (
    <div class={className}>
      <div class="row-label">
        {label} {tooltip && <InfoTooltip text={tooltip} />}
      </div>
      <div class={"row-content " + contentClass}>{content}</div>
    </div>
  );
}

export function RowEuro({
  label,
  tooltip,
  euro,
  positive,
  negative,
  primary,
  prefix = "",
  suffix = "",
  multiline,
}: {
  label: string;
  tooltip?: string;
  euro: string;
  positive?: boolean;
  negative?: boolean;
  primary?: boolean;
  prefix?: string;
  suffix?: string;
  multiline?: boolean;
}) {
  let contentClass = "row-content-number";

  if (positive) {
    contentClass += " row-content-number-positive";
  }

  if (negative) {
    contentClass += " row-content-number-negative";
  }

  suffix = "" + TEXT.euro + " " + suffix;

  if (negative) {
    prefix = "+ " + prefix;
  }

  return (
    <Row
      label={label}
      tooltip={tooltip}
      content={prefix + euro + suffix}
      contentClass={contentClass}
      primary={primary}
      multiline={multiline}
    />
  );
}

export function Checkbox({
  label,
  value,
  onChange,
}: {
  label?: string;
  value: boolean;
  onChange: (value: boolean) => void;
}) {
  return (
    <label class="checkbox">
      {label && <span class="checkbox-label">{label}</span>}
      <input
        type="checkbox"
        checked={value}
        onInput={(e) => {
          // @ts-ignore
          const value: boolean = e.target?.checked;

          onChange(value);
        }}
      />
      <span class="checkbox-checkmark"></span>
    </label>
  );
}

export function NumberSlider({
  value,
  min,
  max,
  onChange,
}: {
  min: number;
  max: number;
  value: number;
  onChange: (value: number) => void;
}) {
  return (
    <input
      type="range"
      value={value}
      min={min}
      max={max}
      onInput={(e) => {
        // @ts-ignore
        const value: number = parseInt(e.target?.value) || 1;

        onChange(value);
      }}
    />
  );
}

export function InfoTooltip({ text }: { text: string }) {
  const [active, setActive] = useState(false);

  const title = text
    .replaceAll("<i>", "")
    .replaceAll("</i>", "")
    .replaceAll("<b>", "")
    .replaceAll("</b>", "")
    .replaceAll("<br>", "");

  return (
    <>
      <span
        class="info-tooltip-icon"
        title={title}
        onClick={() => {
          setActive(true);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          width="1em"
          height="1em"
          fill="currentColor"
          stroke="currentColor"
        >
          <path d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-36 344h12V232h-12c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h48c6.627 0 12 5.373 12 12v140h12c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12h-72c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12zm36-240c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32z" />
        </svg>
      </span>

      {active && (
        <div class="info-tooltip-dialog">
          <div class="info-tooltip-dialog-head">
            <div
              class="info-tooltip-dialog-close"
              onClick={() => {
                setActive(false);
              }}
            />
          </div>
          <div
            class="info-tooltip-dialog-content"
            dangerouslySetInnerHTML={{ __html: text }}
          ></div>
        </div>
      )}
    </>
  );
}

export function RowCheckbox({
  label,
  value,
  onChange,
}: {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
}) {
  return (
    <div class="row">
      <div class="row-label">{label}</div>
      <div class="row-content">
        <Checkbox value={value} onChange={onChange} />
      </div>
    </div>
  );
}

export function RowInput({
  label,
  tooltip,
  value,
  onChange,
}: {
  label: string;
  tooltip?: string;
  value: string;
  onChange: (value: string) => void;
}) {
  return (
    <div class="row">
      <div class="row-label">
        {label} {tooltip && <InfoTooltip text={tooltip} />}
      </div>
      <div class="row-content">
        <input
          type="text"
          value={value}
          onInput={(e) => {
            // @ts-ignore
            const value: string = e.target?.value;

            onChange(value);
          }}
        />
      </div>
    </div>
  );
}

export function RowSelect({
  label,
  value,
  onChange,
  options,
}: {
  label: string;
  value: string;
  onChange: (value: string) => void;
  options: { label: string; value: string }[];
}) {
  return (
    <div class="row">
      <div class="row-label">{label}</div>
      <div class="row-content">
        <select
          value={value}
          onInput={(e) => {
            // @ts-ignore
            const value: string = e.target?.value;

            onChange(value);
          }}
          children={options.map(({ label, value }) => (
            <option value={value}>{label}</option>
          ))}
        />
      </div>
    </div>
  );
}

export function RowOpportunity({
  label,
  description,
  euro,
  kWh,
  checked = false,
  onCheck,
  minSteps = 1,
  maxSteps = 3,
  scale,
  onScale,
  scaleUnit,
}: {
  label: string;
  description?: string;
  euro: string;
  kWh: string;
  checked?: boolean;
  onCheck?: (value: boolean) => void;
  minSteps?: number;
  maxSteps?: number;
  scale?: number;
  onScale?: (value: number) => void;
  scaleUnit?: string;
}) {
  if (!onCheck) {
    return (
      <div class="row row-with-checkbox row-primary">
        <div class="row-checkbox"></div>
        <div class="row-label">{label}</div>
        <div class="row-content row-content-3 row-content-number">
          {euro} {TEXT.euro}
        </div>
        <div class="row-content row-content-3 row-content-number">
          {kWh} {TEXT.kWh}
        </div>
      </div>
    );
  }

  const scaleString = scale ? scale.toString() : "";

  if (!isMobile() && !onScale) {
    return (
      <div class="row row-with-checkbox">
        <div class="row-checkbox">
          {onCheck && <Checkbox value={!!checked} onChange={onCheck} />}
        </div>
        <div class="row-label">
          {label.replace("{scale}", scaleString)}{" "}
          {description && <InfoTooltip text={description} />}
        </div>
        <div class="row-content row-content-3 row-content-number">
          {euro} {TEXT.euro}
        </div>
        <div class="row-content row-content-3 row-content-number">
          {kWh} {TEXT.kWh}
        </div>
      </div>
    );
  }

  return (
    <div class="row-group">
      <div class="row row-with-checkbox">
        <div class="row-checkbox">
          {onCheck && <Checkbox value={!!checked} onChange={onCheck} />}
        </div>
        <div class="row-label">
          {label.replace("{scale}", scaleString)}{" "}
          {description && <InfoTooltip text={description} />}
        </div>
      </div>
      {onScale && (
        <>
          <div class="row row-with-checkbox">
            <div class="row-checkbox"></div>

            <div class="row-slider">
              <NumberSlider
                min={minSteps}
                max={maxSteps}
                value={scale || 1}
                onChange={onScale}
              />
            </div>
          </div>

          {scaleUnit && (
            <div class="row row-with-checkbox">
              <div class="row-checkbox"></div>
              <div class="row-label">
                <i>{scaleUnit}:</i>
              </div>
              <div class="row-content  row-content-number">{scale}</div>
            </div>
          )}
        </>
      )}
      <div class="row row-with-checkbox">
        <div class="row-checkbox"></div>
        <div class="row-label">
          <i>{TEXT.savings}:</i>
        </div>
        <div class="row-content row-content-3 row-content-number">
          {euro} {TEXT.euro}
        </div>
        <div class="row-content row-content-3 row-content-number">
          {kWh} {TEXT.kWh}
        </div>
      </div>
    </div>
  );
}

export const numberFormat = new Intl.NumberFormat("de-DE", {
  useGrouping: true,
});

export function parseNumber(input: string = "0") {
  return parseFloat(input.replace(",", "."));
}

export function formatGas(input: number): string {
  if (!input) {
    return "0";
  }

  return numberFormat.format(Math.round(input * 10) / 10);
}

export function formatEuro(input: number): string {
  if (!input) {
    return "0";
  }

  return numberFormat.format(Math.round(input));

  let result = (Math.round(input * 100) / 100).toString().replace(".", ",");

  let kommaIndex = result.indexOf(",");

  if (kommaIndex >= 0) {
    if (kommaIndex === result.length - 2) {
      result += "0";
    }
  }

  return result;
}

export function isMobile() {
  return window.innerWidth < 600;
}
