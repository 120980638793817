import { Fragment, h } from "preact";
import { useEffect, useRef, useState } from "preact/hooks";
import { IOpportunities } from "../../common";
import {
  Cards,
  formatEuro,
  formatGas,
  isMobile,
  parseNumber,
  RowCheckbox,
  RowEuro,
  RowInput,
  RowOpportunity,
  RowSelect,
} from "../../components/cards";
import { TEXT } from "../../const-strom";

const urlParams = new URLSearchParams(window.location.search);

export default () => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    try {
      const height = Math.round(
        ref.current?.getBoundingClientRect().height || 1000
      );

      if (window.top) {
        window.top.postMessage(
          { isStromSparRechnerMessage: true, height },
          "*"
        );
      }
    } catch (error) {
      console.error(error);
    }
  });

  const devMode = urlParams.get("dev") === "ja";

  const showStateFunding =
    urlParams.get("preisbremse_2023_ausblenden") !== "ja";

  const showOpportunities =
    urlParams.get("einsparoptionen_ausblenden") !== "ja";

  const [step, setStep] = useState(0);

  const [consumtion, setConsumtion] = useState(devMode ? "10000" : "");
  const [currentPriceTotalInput, setCurrentPriceTotalInput] = useState("");
  const [currentBasePrice, setCurrentBasePrice] = useState(devMode ? "10" : "");
  const [currentWorkingPrice, setCurrentWorkingPrice] = useState(
    devMode ? "5" : ""
  );
  const [nextBasePrice, setNextBasePrice] = useState(devMode ? "20" : "");
  const [nextWorkingPrice, setNextWorkingPrice] = useState(devMode ? "20" : "");
  const [stateFunding23Factor, setStateFunding23Factor] = useState("12");

  const [currentPriceTotalKnown, setCurrentPriceTotalKnown] =
    useState<boolean>(false);

  const [applyStateFundung, setApplyStateFunding] = useState<boolean>(false);

  const [opportunityChecks, setOpportunityChecks] = useState<
    Record<string, boolean>
  >({});

  const [opportunityScale, setOpportunityScale] = useState<
    Record<string, number>
  >({});

  // callbacks

  const nextDisabled =
    step === 0 &&
    (consumtion === "" ||
      (currentPriceTotalKnown
        ? currentPriceTotalInput === ""
        : currentBasePrice === "" || currentWorkingPrice === "") ||
      nextBasePrice === "" ||
      nextWorkingPrice === "");

  // calc:

  const consumtionNumber = parseNumber(consumtion);
  const currentPriceTotalInputNumber = parseNumber(currentPriceTotalInput);
  const currentBasePriceNumber = parseNumber(currentBasePrice);
  const currentWorkingPriceNumber = parseNumber(currentWorkingPrice) / 100;
  const nextBasePriceNumber = parseNumber(nextBasePrice);
  const nextWorkingPriceNumber = parseNumber(nextWorkingPrice) / 100;
  const stateFunding23FactorNumber = parseNumber(stateFunding23Factor);

  const currentPriceTotal = currentPriceTotalKnown
    ? currentPriceTotalInputNumber
    : consumtionNumber * currentWorkingPriceNumber + currentBasePriceNumber;

  const newPriceTotal =
    consumtionNumber * nextWorkingPriceNumber + nextBasePriceNumber;

  const newPriceDiff = newPriceTotal - currentPriceTotal;

  const stateFunding23Monthly =
    (Math.max(0, nextWorkingPriceNumber - 0.4) * (consumtionNumber * 0.8)) / 12;
  const stateFunding23 = stateFunding23Monthly * stateFunding23FactorNumber;

  const stateFundingTotal = showStateFunding ? stateFunding23 : 0;

  const newPriceDiffAfterStateFunding = newPriceDiff - stateFundingTotal;

  // opportunities:

  const opportunities: IOpportunities = {
    standby: {
      label: TEXT.opportunities.standby.label,
      description: TEXT.opportunities.standby.description,
      value: 320,
      scale: false,
    },
    devices: {
      label: TEXT.opportunities.devices.label,
      description: TEXT.opportunities.devices.description,
      value: 100,
      scale: true,
      scaleUnit: TEXT.opportunities.devices.scale_unit,
    },
    warmwater: {
      label: TEXT.opportunities.warmwater.label,
      description: TEXT.opportunities.warmwater.description,
      value: 140,
      scale: true,
      scaleUnit: TEXT.opportunities.warmwater.scale_unit,
    },
    uwp: {
      label: TEXT.opportunities.uwp.label,
      description: TEXT.opportunities.uwp.description,
      value: 400,
      scale: false,
    },
  };

  let steps = [
    {
      key: "base",
      title: TEXT.last_annual_statement,
      titleShort: TEXT.last_annual_statement_short,
      description: TEXT.last_annual_statement_description,
      descriptionBeforeTitle: true,
    },
    {
      key: "statefunding",
      title: TEXT.card_statefunding_title,
      titleShort: TEXT.card_statefunding_title_short,
      description: TEXT.card_statefunding_description,
      descriptionBeforeTitle: false,
    },
    {
      key: "opportunities",
      title: TEXT.saving_opportunities,
      titleShort: TEXT.saving_opportunities_short,
      description: TEXT.saving_opportunities_description,
      descriptionBeforeTitle: false,
    },
  ];

  if (!showStateFunding) {
    steps = steps.filter((s) => s.key !== "statefunding");
  }

  if (!showOpportunities) {
    steps = steps.filter((s) => s.key !== "opportunities");
  }

  let consumptionAfterOpportunities = consumtionNumber;

  const opportunitySavings: Record<string, { gas: number; euro: number }> = {};

  for (const [key, { value, factor }] of Object.entries(opportunities)) {
    const checked = !!opportunityChecks[key];
    const scale = opportunityScale[key] || 1;

    let gas = 0;

    if (checked) {
      for (let i = 0; i < scale; i++) {
        let reduction = value || 0;

        if (factor) {
          reduction += consumptionAfterOpportunities * factor;
        }

        gas += Math.round(reduction);
        consumptionAfterOpportunities -= reduction;
      }
    }

    const euro = Math.round(gas * nextWorkingPriceNumber);

    opportunitySavings[key] = { gas, euro };
  }

  // opportunity total:

  const opportunityTotalSavingGas = Object.values(opportunitySavings)
    .map((s) => s.gas)
    .reduce((acc, current) => acc + current, 0);

  const opportunityTotalSavingEuro = Object.values(opportunitySavings)
    .map((s) => s.euro)
    .reduce((acc, current) => acc + current, 0);

  const newPriceDiffAfterOpportunities =
    newPriceDiff - opportunityTotalSavingEuro;

  const newPriceAfterOpportunities =
    newPriceDiffAfterOpportunities + currentPriceTotal;

  const newPriceDiffAfterOpportunitiesAndStateFunding =
    newPriceDiff - opportunityTotalSavingEuro - stateFundingTotal;

  return (
    <div class="iframe-default" ref={ref}>
      <Cards
        step={step}
        onStep={setStep}
        steps={steps}
        nextDisabled={nextDisabled}
        warning={
          consumtionNumber >= 30000 ? TEXT.current_consumtion_limit : undefined
        }
      >
        {step === 0 && (
          <>
            <RowInput
              label={TEXT.current_consumtion}
              tooltip={TEXT.current_consumtion_info}
              value={consumtion}
              onChange={setConsumtion}
            />
            {/* <RowCheckbox
            label={TEXT.current_price_total_known}
            value={currentPriceTotalKnown}
            onChange={setCurrentPriceTotalKnown}
          /> */}
            {currentPriceTotalKnown && (
              <Fragment>
                <RowInput
                  label={TEXT.current_price_total_euro}
                  value={currentPriceTotalInput}
                  onChange={setCurrentPriceTotalInput}
                />
              </Fragment>
            )}
            {!currentPriceTotalKnown && (
              <Fragment>
                <RowInput
                  label={TEXT.current_base_price}
                  value={currentBasePrice}
                  onChange={setCurrentBasePrice}
                />
                <RowInput
                  label={TEXT.current_working_price}
                  value={currentWorkingPrice}
                  onChange={setCurrentWorkingPrice}
                />
                <RowEuro
                  label={TEXT.current_price_total}
                  euro={formatEuro(currentPriceTotal)}
                />
              </Fragment>
            )}

            {!isMobile() && <div style={{ height: "var(--lineHeight)" }}></div>}

            <div class="card-header">
              <div class="card-title">{TEXT.new_price}</div>
            </div>

            <RowInput
              label={TEXT.new_base_price}
              value={nextBasePrice}
              onChange={setNextBasePrice}
            />
            <RowInput
              label={TEXT.new_working_price}
              value={nextWorkingPrice}
              onChange={setNextWorkingPrice}
            />
            <RowEuro
              label={TEXT.new_price_total}
              euro={formatEuro(newPriceTotal)}
            />

            <RowEuro
              label={TEXT.new_price_difference}
              euro={formatEuro(newPriceDiff)}
              negative
            />
          </>
        )}

        {step === 1 && showStateFunding && (
          <>
            {/* <RowEuro
              label={TEXT.new_price_total}
              euro={formatEuro(newPriceTotal)}
            /> */}

            <RowEuro
              label={TEXT.new_price_difference}
              euro={formatEuro(newPriceDiff)}
              negative
            />

            {!isMobile() && <div style={{ height: "var(--lineHeight)" }}></div>}

            <div class="card-header">
              <div class="card-title">{TEXT.card_statefunding_title_short}</div>
            </div>

            <RowEuro
              label={TEXT.state_funding_23}
              tooltip={TEXT.state_funding_23_tooltip}
              euro={formatEuro(stateFunding23Monthly)}
              prefix={TEXT.ca}
              suffix={TEXT.per_month}
              positive
            />

            <RowSelect
              label={TEXT.state_funding_23_factor}
              value={stateFunding23Factor}
              onChange={setStateFunding23Factor}
              options={Array(12)
                .fill("")
                .map((_, i) => (i + 1).toString())
                .map((value) => ({
                  value,
                  label: value === "1" ? "Einen Monat" : `${value} Monate`,
                }))}
            />

            {!isMobile() && <div style={{ height: "var(--lineHeight)" }}></div>}

            <RowEuro
              label={TEXT.state_funding_total}
              euro={formatEuro(stateFundingTotal)}
              prefix={TEXT.ca}
              positive
              primary
            />

            <RowEuro
              label={TEXT.new_price_difference_after_state_funding}
              euro={formatEuro(newPriceDiffAfterStateFunding)}
              negative
            />
          </>
        )}

        {(step === 2 || (!showStateFunding && step === 1)) && (
          <>
            {showStateFunding && (
              <RowEuro
                label={TEXT.new_price_difference}
                euro={formatEuro(newPriceDiffAfterStateFunding)}
                negative
              />
            )}

            {Object.entries(opportunities).map(
              ([key, { label, description, scale, scaleUnit }]) => {
                return (
                  <RowOpportunity
                    label={label}
                    description={description}
                    euro={formatEuro(opportunitySavings[key].euro)}
                    kWh={formatGas(opportunitySavings[key].gas)}
                    checked={opportunityChecks[key]}
                    onCheck={(checked) =>
                      setOpportunityChecks((current) => ({
                        ...current,
                        [key]: checked,
                      }))
                    }
                    maxSteps={6}
                    scale={(scale && opportunityScale[key]) || 1}
                    onScale={
                      scale
                        ? (value) =>
                            setOpportunityScale((current) => ({
                              ...current,
                              [key]: value,
                            }))
                        : undefined
                    }
                    scaleUnit={scaleUnit}
                  />
                );
              }
            )}

            <RowOpportunity
              label={TEXT.opportunity_total}
              euro={formatEuro(opportunityTotalSavingEuro)}
              kWh={formatGas(opportunityTotalSavingGas)}
            />

            <RowEuro
              label={TEXT.new_price_difference_after_saving_opportunities}
              euro={formatEuro(newPriceDiffAfterOpportunitiesAndStateFunding)}
              multiline
              positive={newPriceDiffAfterOpportunitiesAndStateFunding < 0}
              negative={newPriceDiffAfterOpportunitiesAndStateFunding > 0}
            />
          </>
        )}
      </Cards>
    </div>
  );
};
