export const TEXT = {
  card_title: "Ihre {{UNIT}}-Vertragsdaten",
  card_title_short: "Abrechnung & Verbrauch",
  card_description: "Mit diesem Rechner kalkulieren Sie anhand weniger Basisdaten die Höhe Ihrer {{UNIT}}kosten im laufenden Jahr und können Ihre staatliche Unterstützung durch die {{UNIT}}preisbremse berechnen.<br> Hinweis: Bitte beachten Sie, dass die errechneten Beträge unverbindlich sind und lediglich der Orientierung dienen.",

  consumtion: "Ihr Verbrauch (kWh / Jahr)",
  consumtion_tooltip: "Um den exakten Wert für die Entlastung im Bereich {{UNIT}} zu berechnen, benötigen wir den aktuellen Prognosewert. Dieser Wert ist von Kunde zu Kunde und von Region zu Region unterschiedlich. Aus Gründen der Vereinfachung haben wir uns entschieden, hier als Näherungswert den letztjährigen Verbrauch anzulegen. Aus diesem Grund können die tatsächlichen Werte leicht abweichen.",
  base_price: "Aktueller Grundpreis (€ / Jahr)",
  working_price: "Aktueller Arbeitspreis (ct / kWh)",
  new_customer: "Neukunde?",

  price_annual_without_statefunding: "Jahreskosten ohne Preisbremse",
  price_annual_with_statefunding: "Jahreskosten mit Preisbremse",
  price_monthly_without_statefunding: "Monatliche Kosten ohne Preisbremse",
  price_monthly_with_statefunding: "Monatliche Kosten mit Preisbremse",

  state_funding_title_short: "{{UNIT}}-Preisbremse",
  state_funding_monthly: "Staatliche Entlastung",
  state_funding_monthly_tooltip: "...",
  state_funding_monthly_tooltip__strom: "Die {{UNIT}}-Preisbremse gilt von März 2023 bis Dezember 2023, wird aber auch rückwirkend auf Januar und Februar angewendet. Private Haushalte sowie Kleingewerbe müssen 40 Cent Brutto pro Kilowattstunde {{UNIT}} zahlen. Dies gilt für bis zu 80 Prozent der aktuellen Jahresverbrauchsprognose. Der im jeweiligen Tarif darüberliegende Anteil am Arbeitspreis wird vom Bund übernommen. Dies finanziert er über Steuermittel sowie aus den Einnahmen der Abschöpfung der bei Energiekonzernen angefallenen Übergewinne/Zufallsgewinne. Für die restlichen 20 Prozent gilt der reguläre Preis, d.h. für diesen Anteil müssen Sie den in Ihrem Tarif geltenden Arbeitspreis zahlen. Das heißt aber auch: Sparen lohnt sich hier besonders!",
  state_funding_monthly_tooltip__gas: "Die {{UNIT}}-Preisbremse gilt von März 2023 bis Dezember 2023, wird aber auch rückwirkend auf Januar und Februar angewendet. Private Haushalte sowie Kleingewerbe müssen 12 Cent Brutto pro Kilowattstunde {{UNIT}} zahlen. Dies gilt für bis zu 80 Prozent des im September 2022 prognostizierten {{UNIT}}bedarfs für das Folgejahr. Der im jeweiligen Tarif darüberliegende Anteil am Arbeitspreis wird vom Bund übernommen. Dies finanziert er über Steuermittel sowie aus den Einnahmen der Abschöpfung der bei Energiekonzernen angefallenen Übergewinne/Zufallsgewinne. Für die restlichen 20 Prozent gilt der reguläre Preis, d.h. für diesen Anteil müssen Sie den in Ihrem Tarif geltenden Arbeitspreis zahlen. Das heißt aber auch: Sparen lohnt sich hier besonders!",
  state_funding_monthly_tooltip__waerme: "Die {{UNIT}}-Preisbremse gilt von März 2023 bis Dezember 2023, wird aber auch rückwirkend auf Januar und Februar angewendet. Private Haushalte sowie Kleingewerbe müssen 9,5 Cent Brutto pro Kilowattstunde {{UNIT}} zahlen. Dies gilt für bis zu 80 Prozent des im September 2022 prognostizierten {{UNIT}}bedarfs für das Folgejahr. Der im jeweiligen Tarif darüberliegende Anteil am Arbeitspreis wird vom Bund übernommen. Dies finanziert er über Steuermittel sowie aus den Einnahmen der Abschöpfung der bei Energiekonzernen angefallenen Übergewinne/Zufallsgewinne. Für die restlichen 20 Prozent gilt der reguläre Preis, d.h. für diesen Anteil müssen Sie den in Ihrem Tarif geltenden Arbeitspreis zahlen. Das heißt aber auch: Sparen lohnt sich hier besonders!",

  state_funding_factor: "Bestandskunde oder Neukunde?",
  state_funding_total: "Unterstützungsbetrag",
  per_month: " pro Monat",
  ca: "ca. ",
};
