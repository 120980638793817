import { h } from "preact";
import { useEffect, useRef } from "preact/hooks";

export function DemoHandler() {
  const host = window.location.host;
  const protocol = host.includes("localhost") ? "http://" : "https://";
  const rows = [
    [
      {
        id: "asew-gassparrechner",
        path: "/iframe/default?dev=ja",
        script: "stromsparrechner.js",
      },
      {
        id: "asew-stromsparrechner",
        path: "/iframe/stromsparrechner?dev=ja",
        script: "stromsparrechner.js",
      },
      {
        id: "asew-einsparrechner-waerme",
        path: "/iframe/waerme?dev=ja",
        script: "einsparrechner.js",
      },
      {
        id: "asew-einsparrechner-gas",
        path: "/iframe/gas?dev=ja",
        script: "einsparrechner.js",
      },
      {
        id: "asew-einsparrechner-strom",
        path: "/iframe/strom?dev=ja",
        script: "einsparrechner.js",
      },
    ],
    [
      {
        id: "asew-preisbremse-gas",
        path: "/iframe/preisbremse-gas?dev=ja",
        script: "preisbremse.js",
      },
      {
        id: "asew-preisbremse-strom",
        path: "/iframe/preisbremse-strom?dev=ja",
        script: "preisbremse.js",
      },
      {
        id: "asew-preisbremse-waerme",
        path: "/iframe/preisbremse-waerme?dev=ja",
        script: "preisbremse.js",
      },
    ],
    [
      {
        id: "asew-rheinenergie-gas",
        path: "/iframe/rheinenergie-gas?dev=ja",
        script: "rheinenergie.js",
      },
      {
        id: "asew-rheinenergie-strom",
        path: "/iframe/rheinenergie-strom?dev=ja",
        script: "rheinenergie.js",
      },
      {
        id: "asew-rheinenergie-waerme",
        path: "/iframe/rheinenergie-waerme?dev=ja",
        script: "rheinenergie.js",
      },
    ],
  ];

  return (
    <div className="demo-page">
      {rows.map((row, i) => {
        return (
          <div key={i} className="demo-page-row">
            {row.map((col, i) => {
              const url = `${protocol}${host}${col.path}`;
              const iframe = `<iframe id="${col.id}" src="${url}" style="border: 0; width: 100%; height: 800px;"></iframe>`;
              const js = `<script src="${protocol}${host}/assets/${col.script}"></script>`;

              const code = [iframe, js].join("\n");

              return (
                <div key={i} className="demo-page-col">
                  <h1 children={col.id} />
                  <a href={url} children={url} />
                  <pre className="demo-page-code" children={code} />
                  <DangerouslySetHtmlContent
                    className="demo-page-preview"
                    html={code}
                  />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

function DangerouslySetHtmlContent({
  html,
  dangerouslySetInnerHTML,
  ...rest
}: any) {
  // We remove 'dangerouslySetInnerHTML' from props passed to the div
  const divRef = useRef<HTMLDivElement>(null);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!html || !divRef.current) throw new Error("html prop cant't be null");
    if (!isFirstRender.current) return;
    isFirstRender.current = false;

    const slotHtml = document.createRange().createContextualFragment(html); // Create a 'tiny' document and parse the html string
    divRef.current.innerHTML = ""; // Clear the container
    divRef.current.appendChild(slotHtml); // Append the new content
  }, [html, divRef]);

  // eslint-disable-next-line react/react-in-jsx-scope
  return <div {...rest} ref={divRef} />;
}
