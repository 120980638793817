import { Fragment, h } from "preact";
import { useState, useRef, useEffect } from "preact/hooks";
import { TEXT } from "../../const";

const lang = "de";

function isMobile() {
  return window.innerWidth < 600;
}

const urlParams = new URLSearchParams(window.location.search);

export default () => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    try {
      const height = Math.round(
        ref.current?.getBoundingClientRect().height || 1000
      );

      if (window.top) {
        window.top.postMessage({ isGassparRechnerMessage: true, height }, "*");
      }
    } catch (error) {
      console.error(error);
    }
  });

  const showStateFunding = false;

  const [step, setStep] = useState(1);

  const [consumtion, setConsumtion] = useState("");
  const [currentPriceTotalInput, setCurrentPriceTotalInput] = useState("");
  const [currentBasePrice, setCurrentBasePrice] = useState("");
  const [currentWorkingPrice, setCurrentWorkingPrice] = useState("");
  const [nextBasePrice, setNextBasePrice] = useState("");
  const [nextWorkingPrice, setNextWorkingPrice] = useState("");

  const [currentPriceTotalKnown, setCurrentPriceTotalKnown] =
    useState<boolean>(false);

  const [opportunityChecks, setOpportunityChecks] = useState<
    Record<string, boolean>
  >({});

  const [opportunityScale, setOpportunityScale] = useState<
    Record<string, number>
  >({});

  // callbacks

  const next = () => setStep((s) => s + 1);
  const back = () => setStep((s) => s - 1);

  const nextDisabledStep1 =
    consumtion === "" ||
    (currentPriceTotalKnown
      ? currentPriceTotalInput === ""
      : currentBasePrice === "" || currentWorkingPrice === "") ||
    nextBasePrice === "" ||
    nextWorkingPrice === "";

  // calc:

  const consumtionNumber = parseNumber(consumtion);
  const currentPriceTotalInputNumber = parseNumber(currentPriceTotalInput);
  const currentBasePriceNumber = parseNumber(currentBasePrice);
  const currentWorkingPriceNumber = parseNumber(currentWorkingPrice) / 100;
  const nextBasePriceNumber = parseNumber(nextBasePrice);
  const nextWorkingPriceNumber = parseNumber(nextWorkingPrice) / 100;

  const currentPriceTotal = currentPriceTotalKnown
    ? currentPriceTotalInputNumber
    : consumtionNumber * currentWorkingPriceNumber + currentBasePriceNumber;

  const newPriceTotal =
    consumtionNumber * nextWorkingPriceNumber + nextBasePriceNumber;

  const newPriceDiff = newPriceTotal - currentPriceTotal;

  const stateFunding = showStateFunding ? newPriceTotal / 12 : 0;
  const newPriceDiffAfterStateFunding = newPriceDiff - stateFunding;

  // opportunities:

  const opportunities = {
    one_degree: {
      label: TEXT.opportunities.one_degree.label,
      description: TEXT.opportunities.one_degree.description,
      factor: 0.06,
      scale: true,
    },
    vent_radiators: {
      label: TEXT.opportunities.vent_radiators.label,
      description: TEXT.opportunities.vent_radiators.description,
      factor: 0.15,
      scale: false,
    },
    maintenance: {
      label: TEXT.opportunities.maintenance.label,
      description: TEXT.opportunities.maintenance.description,
      factor: 0.05,
      scale: false,
    },
    insulate_heating_pipes: {
      label: TEXT.opportunities.insulate_heating_pipes.label,
      description: TEXT.opportunities.insulate_heating_pipes.description,
      factor: 0.08,
      scale: false,
    },
    clear_radiators: {
      label: TEXT.opportunities.clear_radiators.label,
      description: TEXT.opportunities.clear_radiators.description,
      factor: 0.12,
      scale: false,
    },
    shutter: {
      label: TEXT.opportunities.shutters.label,
      description: TEXT.opportunities.shutters.description,
      factor: 0.06,
      scale: false,
    },
  };

  let consumptionAfterOpportunities = consumtionNumber;

  const opportunitySavings: Record<string, { gas: number; euro: number }> = {};

  for (const [key, { factor }] of Object.entries(opportunities)) {
    const checked = !!opportunityChecks[key];
    const scale = opportunityScale[key] || 1;

    let gas = 0;

    if (checked) {
      for (let i = 0; i < scale; i++) {
        const reduction = consumptionAfterOpportunities * factor;
        gas += Math.round(reduction);
        consumptionAfterOpportunities -= reduction;
      }
    }

    const euro = Math.round(gas * nextWorkingPriceNumber);

    opportunitySavings[key] = { gas, euro };
  }

  // opportunity total:

  const opportunityTotalSavingGas = Object.values(opportunitySavings)
    .map((s) => s.gas)
    .reduce((acc, current) => acc + current, 0);

  const opportunityTotalSavingEuro = Object.values(opportunitySavings)
    .map((s) => s.euro)
    .reduce((acc, current) => acc + current, 0);

  const newPriceDiffAfterOpportunities =
    newPriceDiffAfterStateFunding - opportunityTotalSavingEuro;

  const newPriceAfterOpportunities =
    newPriceDiffAfterOpportunities + currentPriceTotal;

  return (
    <div class="iframe-default" ref={ref}>
      <Cards step={step} stepMax={3} saving={0}>
        <Card
          show={step === 1}
          title={TEXT.last_annual_statement}
          description={""}
          nextDisabled={nextDisabledStep1}
          onNext={next}
          intro
        >
          <RowInput
            label={TEXT.current_consumtion}
            value={consumtion}
            onChange={setConsumtion}
          />
          {/* <RowCheckbox
            label={TEXT.current_price_total_known}
            value={currentPriceTotalKnown}
            onChange={setCurrentPriceTotalKnown}
          /> */}
          {currentPriceTotalKnown && (
            <Fragment>
              <RowInput
                label={TEXT.current_price_total_euro}
                value={currentPriceTotalInput}
                onChange={setCurrentPriceTotalInput}
              />
            </Fragment>
          )}
          {!currentPriceTotalKnown && (
            <Fragment>
              <RowInput
                label={TEXT.current_base_price}
                value={currentBasePrice}
                onChange={setCurrentBasePrice}
              />
              <RowInput
                label={TEXT.current_working_price}
                value={currentWorkingPrice}
                onChange={setCurrentWorkingPrice}
              />
              <RowEuro
                label={TEXT.current_price_total}
                euro={formatEuro(currentPriceTotal)}
              />
            </Fragment>
          )}

          {!isMobile() && <div style={{ height: 55 }}></div>}

          <div class="card-header">
            <div class="card-title">{TEXT.new_price}</div>
          </div>

          <RowInput
            label={TEXT.new_base_price}
            value={nextBasePrice}
            onChange={setNextBasePrice}
          />
          <RowInput
            label={TEXT.new_working_price}
            value={nextWorkingPrice}
            onChange={setNextWorkingPrice}
          />
          <RowEuro
            label={TEXT.new_price_total}
            euro={formatEuro(newPriceTotal)}
          />

          {showStateFunding && (
            <RowEuro
              label={TEXT.state_funding_dec22}
              tooltip={TEXT.state_funding_dec22_tooltip}
              euro={formatEuro(stateFunding)}
              positive
              ca
            />
          )}

          <RowEuro
            label={TEXT.new_price_difference}
            euro={formatEuro(newPriceDiffAfterStateFunding)}
            negative
          />
          {/*
          <RowEuro
            label={TEXT.new_price_difference_after_state_funding}
            euro={newPriceDiffAfterStateFunding}
          /> */}

          {!isMobile() && <div style={{ height: 40 }}></div>}
        </Card>
        <Card
          show={step === 2}
          title={TEXT.saving_opportunities}
          description={TEXT.description_step3}
          // onNext={next}
          onBack={back}
        >
          {Object.entries(opportunities).map(
            ([key, { label, description, scale }]) => {
              return (
                <RowOpportunity
                  label={label}
                  description={description}
                  euro={formatEuro(opportunitySavings[key].euro)}
                  kWh={formatGas(opportunitySavings[key].gas)}
                  checked={opportunityChecks[key]}
                  onCheck={(checked) =>
                    setOpportunityChecks((current) => ({
                      ...current,
                      [key]: checked,
                    }))
                  }
                  scale={(scale && opportunityScale[key]) || 1}
                  onScale={
                    scale
                      ? (value) =>
                          setOpportunityScale((current) => ({
                            ...current,
                            [key]: value,
                          }))
                      : undefined
                  }
                />
              );
            }
          )}

          <RowOpportunity
            label={TEXT.opportunity_total}
            euro={formatEuro(opportunityTotalSavingEuro)}
            kWh={formatGas(opportunityTotalSavingGas)}
          />

          <RowEuro
            label={TEXT.new_price_difference_after_saving_opportunities}
            euro={formatEuro(newPriceDiffAfterOpportunities)}
          />

          <RowEuro
            label={TEXT.new_price_after_saving_opportunities}
            euro={formatEuro(newPriceAfterOpportunities)}
          />
        </Card>
      </Cards>
    </div>
  );
};

function Cards({
  step,
  stepMax,
  saving,
  children,
}: {
  step: number;
  stepMax: number;
  saving: number;
  children: any;
}) {
  return (
    <div class="cards">
      {/* <div class="cards-head">
        <div class="cards-step">
          {step} <span>/ {stepMax}</span>
        </div>
        {!!saving && (
          <div class="cards-saving">
            {saving} {TEXT.euro}
          </div>
        )}
      </div> */}
      <div class="cards-content">{children}</div>
    </div>
  );
}

function Card({
  show,
  title,
  description,
  nextDisabled,
  onNext,
  onBack,
  children,
  intro,
}: {
  show: boolean;
  intro?: boolean;
  title: string;
  description: string;
  nextDisabled?: boolean;
  onNext?(): void;
  onBack?(): void;
  children: any;
}) {
  if (!show) {
    return null;
  }

  return (
    <div class="card">
      <div class="card-header">
        {intro && (
          <div class="card-description">
            {TEXT.description_step1}
            <br></br>
            <br></br>
          </div>
        )}
        {!!title && <div class="card-title">{title}</div>}
        {!!description && <div class="card-description">{description}</div>}
      </div>
      <div class="card-content">{children}</div>
      <div class="card-footer">
        <div class="card-footer-child"></div>
        <div class="card-footer-child">
          {onBack && (
            <button
              class="button-outline"
              onClick={onBack}
              children={TEXT.back}
            />
          )}
          {onNext && (
            <button
              disabled={nextDisabled}
              onClick={onNext}
              children={TEXT.next}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function Row({
  label,
  tooltip,
  content = "",
  title,
  primary,
  contentClass = "",
}: {
  label: string;
  tooltip?: string;
  content?: string;
  title?: boolean;
  primary?: boolean;
  contentClass?: string;
}) {
  let className = "row";

  if (title) {
    className += " row-title";
  }

  if (primary) {
    className += " row-primary";
  }

  return (
    <div class={className}>
      <div class="row-label">
        {label} {tooltip && <InfoTooltip text={tooltip} />}
      </div>
      <div class={"row-content " + contentClass}>{content}</div>
    </div>
  );
}

function RowEuro({
  label,
  tooltip,
  euro,
  positive,
  negative,
  ca,
}: {
  label: string;
  tooltip?: string;
  euro: string;
  positive?: boolean;
  negative?: boolean;
  ca?: boolean;
}) {
  let contentClass = "row-content-number";

  if (positive) {
    contentClass += " row-content-number-positive";
  }

  if (negative) {
    contentClass += " row-content-number-negative";
  }

  let prefix = "";
  let suffix = " " + TEXT.euro;

  if (positive) {
    prefix = "- ";
  }

  if (negative) {
    prefix = "+ ";
  }

  if (ca) {
    prefix = "ca. " + prefix;
  }

  return (
    <Row
      label={label}
      tooltip={tooltip}
      content={prefix + euro + suffix}
      contentClass={contentClass}
    />
  );
}

function Checkbox({
  label,
  value,
  onChange,
}: {
  label?: string;
  value: boolean;
  onChange: (value: boolean) => void;
}) {
  return (
    <label class="checkbox">
      {label && <span class="checkbox-label">{label}</span>}
      <input
        type="checkbox"
        checked={value}
        onInput={(e) => {
          // @ts-ignore
          const value: boolean = e.target?.checked;

          onChange(value);
        }}
      />
      <span class="checkbox-checkmark"></span>
    </label>
  );
}

function NumberSlider({
  value,
  min,
  max,
  onChange,
}: {
  min: number;
  max: number;
  value: number;
  onChange: (value: number) => void;
}) {
  return (
    <input
      type="range"
      value={value}
      min={min}
      max={max}
      onInput={(e) => {
        // @ts-ignore
        const value: number = parseInt(e.target?.value) || 1;

        onChange(value);
      }}
    />
  );
}

function InfoTooltip({ text }: { text: string }) {
  const [active, setActive] = useState(false);
  return (
    <>
      <span
        class="info-tooltip-icon"
        title={text}
        onClick={() => {
          setActive(true);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          width="1em"
          height="1em"
          fill="currentColor"
          stroke="currentColor"
        >
          <path d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-36 344h12V232h-12c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h48c6.627 0 12 5.373 12 12v140h12c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12h-72c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12zm36-240c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32z" />
        </svg>
      </span>

      {active && (
        <div class="info-tooltip-dialog">
          <div class="info-tooltip-dialog-head">
            <div
              class="info-tooltip-dialog-close"
              onClick={() => {
                setActive(false);
              }}
            />
          </div>
          <div class="info-tooltip-dialog-content">{text}</div>
        </div>
      )}
    </>
  );
}

function RowCheckbox({
  label,
  value,
  onChange,
}: {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
}) {
  return (
    <div class="row">
      <div class="row-label">{label}</div>
      <div class="row-content">
        <Checkbox value={value} onChange={onChange} />
      </div>
    </div>
  );
}

function RowInput({
  label,
  value,
  onChange,
}: {
  label: string;
  value: string;
  onChange: (value: string) => void;
}) {
  return (
    <div class="row">
      <div class="row-label">{label}</div>
      <div class="row-content">
        <input
          type="text"
          value={value}
          onInput={(e) => {
            // @ts-ignore
            const value: string = e.target?.value;

            onChange(value);
          }}
        />
      </div>
    </div>
  );
}

function RowOpportunity({
  label,
  description,
  euro,
  kWh,
  checked = false,
  onCheck,
  scale,
  onScale,
}: {
  label: string;
  description?: string;
  euro: string;
  kWh: string;
  checked?: boolean;
  onCheck?: (value: boolean) => void;
  scale?: number;
  onScale?: (value: number) => void;
}) {
  if (!onCheck) {
    return (
      <div class="row row-with-checkbox row-primary">
        <div class="row-checkbox"></div>
        <div class="row-label">{label}</div>
        <div class="row-content row-content-3 row-content-number">
          {euro} {TEXT.euro}
        </div>
        <div class="row-content row-content-3 row-content-number">
          {kWh} {TEXT.kWh}
        </div>
      </div>
    );
  }

  const scaleString = scale ? scale.toString() : "";

  if (!isMobile() && !onScale) {
    return (
      <div class="row row-with-checkbox">
        <div class="row-checkbox">
          {onCheck && <Checkbox value={!!checked} onChange={onCheck} />}
        </div>
        <div class="row-label">
          {label.replace("{scale}", scaleString)}{" "}
          {description && <InfoTooltip text={description} />}
        </div>
        <div class="row-content row-content-3 row-content-number">
          {euro} {TEXT.euro}
        </div>
        <div class="row-content row-content-3 row-content-number">
          {kWh} {TEXT.kWh}
        </div>
      </div>
    );
  }

  return (
    <div class="row-group">
      <div class="row row-with-checkbox">
        <div class="row-checkbox">
          {onCheck && <Checkbox value={!!checked} onChange={onCheck} />}
        </div>
        <div class="row-label">
          {label.replace("{scale}", scaleString)}{" "}
          {description && <InfoTooltip text={description} />}
        </div>
      </div>
      {onScale && (
        <div class="row row-with-checkbox">
          <div class="row-checkbox"></div>

          <div class="row-slider">
            <NumberSlider
              min={1}
              max={3}
              value={scale || 1}
              onChange={onScale}
            />
          </div>
        </div>
      )}
      <div class="row row-with-checkbox">
        <div class="row-checkbox"></div>
        <div class="row-label">
          <i>{TEXT.savings}:</i>
        </div>
        <div class="row-content row-content-3 row-content-number">
          {euro} {TEXT.euro}
        </div>
        <div class="row-content row-content-3 row-content-number">
          {kWh} {TEXT.kWh}
        </div>
      </div>
    </div>
  );
}

function parseNumber(input: string = "0") {
  return parseFloat(input.replace(",", "."));
}

function formatGas(input: number): string {
  if (!input) {
    return "0";
  }

  return (Math.round(input * 10) / 10).toString().replace(".", ",");
}

function formatEuro(input: number): string {
  if (!input) {
    return "0";
  }

  return Math.round(input).toString().replace(".", ",");

  let result = (Math.round(input * 100) / 100).toString().replace(".", ",");

  let kommaIndex = result.indexOf(",");

  if (kommaIndex >= 0) {
    if (kommaIndex === result.length - 2) {
      result += "0";
    }
  }

  return result;
}
