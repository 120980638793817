import { Fragment, h } from "preact";
import { useEffect, useRef, useState } from "preact/hooks";
import { calcMonthlyStateFunding, fixTexts } from "../../common";
import {
  Cards,
  formatEuro,
  InfoTooltip,
  isMobile,
  parseNumber,
  RowCheckbox,
  RowEuro,
  RowInput,
  RowSelect,
} from "../../components/cards";
import { TEXT } from "../../texts/rheinenergie";

const urlParams = new URLSearchParams(window.location.search);

export function RheinEnergieStrom() {
  return <Handler type="strom" />;
}
export function RheinEnergieGas() {
  return <Handler type="gas" />;
}
export function RheinEnergieWaerme() {
  return <Handler type="waerme" />;
}

function Handler({ type }: { type: "strom" | "gas" | "waerme" }) {
  const ref = useRef<HTMLDivElement>(null);

  fixTexts(TEXT, type);

  useEffect(() => {
    try {
      const height = Math.round(
        ref.current?.getBoundingClientRect().height || 1000
      );

      if (window.top) {
        window.top.postMessage(
          { isPreisbremseMessage: true, type, height },
          "*"
        );
      }
    } catch (error) {
      console.error(error);
    }
  });

  const devMode = urlParams.get("dev") === "ja";

  const stateFundingFactorDefault = (12 - new Date().getMonth()).toString();

  const [consumtion, setConsumtion] = useState(devMode ? "10000000" : "");
  const [nextBasePrice, setNextBasePrice] = useState(devMode ? "20" : "");
  const [nextWorkingPrice, setNextWorkingPrice] = useState(devMode ? "50" : "");
  const [stateFundingFactor, setStateFundingFactor] = useState("12");

  // calc:

  const consumtionNumber = parseNumber(consumtion);
  const nextBasePriceNumber = parseNumber(nextBasePrice);
  const nextWorkingPriceNumber = parseNumber(nextWorkingPrice) / 100;
  const stateFundingFactorNumber = parseNumber(stateFundingFactor);

  const newPriceTotal =
    consumtionNumber * nextWorkingPriceNumber + nextBasePriceNumber;

  const stateFundingMonthly = calcMonthlyStateFunding(
    type,
    consumtionNumber,
    nextWorkingPriceNumber
  );

  const monthlyPrice =
    (consumtionNumber * nextWorkingPriceNumber + nextBasePriceNumber) / 12;
  const monthlyPriceWithStateFunding = monthlyPrice - stateFundingMonthly;

  const stateFundingTotal = stateFundingMonthly * stateFundingFactorNumber;

  const priceTotal = newPriceTotal - stateFundingTotal;
  const priceTotalMonthly = priceTotal / 12;

  const steps = [
    {
      title: TEXT.card_title,
      titleShort: TEXT.card_title_short,
      description: TEXT.card_description,
      descriptionBeforeTitle: true,
    },
  ];

  return (
    <div class="iframe-default" ref={ref}>
      <Cards step={0} onStep={() => void 0} steps={steps}>
        <>
          <RowInput
            label={TEXT.consumtion}
            tooltip={TEXT.consumtion_tooltip}
            value={consumtion}
            onChange={setConsumtion}
          />
          <RowInput
            label={TEXT.base_price}
            value={nextBasePrice}
            onChange={setNextBasePrice}
          />
          <RowInput
            label={TEXT.working_price}
            value={nextWorkingPrice}
            onChange={setNextWorkingPrice}
          />
          {/* <RowCheckbox
            label={TEXT.new_customer}
            value={stateFundingFactor === stateFundingFactorDefault}
            onChange={(value) => {
              if (value) {
                setStateFundingFactor(stateFundingFactorDefault);
              } else {
                setStateFundingFactor("12");
              }
            }}
          />
           */}
          {/* <RowSelect
            label={TEXT.state_funding_factor}
            value={stateFundingFactor}
            onChange={setStateFundingFactor}
            // options={Array(12)
            //   .fill("")
            //   .map((_, i) => (i + 1).toString())
            //   .map((value) => ({
            //     value,
            //     label: value === "1" ? "Einen Monat" : `${value} Monate`,
            //   }))}
            options={[
              {
                label: "Bestandskunde",
                value: "12",
              },
              {
                label: "Neukunde",
                value: stateFundingFactorDefault,
              },
            ]}
          /> */}
          <RowEuro
            label={TEXT.price_annual_without_statefunding}
            euro={formatEuro(newPriceTotal)}
          />

          {!isMobile() && <div style={{ height: "var(--lineHeight)" }}></div>}

          <div class="card-header">
            <div class="card-title">
              {TEXT.state_funding_title_short}{" "}
              <InfoTooltip text={TEXT.state_funding_monthly_tooltip} />
            </div>
          </div>

          {/* <RowEuro
            label={TEXT.state_funding_monthly}
            tooltip={TEXT.state_funding_monthly_tooltip}
            euro={formatEuro(stateFundingMonthly)}
            prefix={TEXT.ca}
            suffix={TEXT.per_month}
            positive={stateFundingMonthly > 0}
          />

          <RowEuro
            label={TEXT.price_monthly_without_statefunding}
            euro={formatEuro(monthlyPrice)}
            prefix={TEXT.ca}
            suffix={TEXT.per_month}
          />
          <RowEuro
            label={TEXT.price_monthly_with_statefunding}
            euro={formatEuro(monthlyPriceWithStateFunding)}
            prefix={TEXT.ca}
            suffix={TEXT.per_month}
          /> */}

          {/* <RowEuro
            label={TEXT.price_monthly_with_statefunding}
            euro={formatEuro(monthlyPriceWithStateFunding)}
            prefix={TEXT.ca}
            suffix={TEXT.per_month}
          /> */}
          {/* 
          <RowSelect
            label={TEXT.state_funding_factor}
            value={stateFundingFactor}
            onChange={setStateFundingFactor}
            // options={Array(12)
            //   .fill("")
            //   .map((_, i) => (i + 1).toString())
            //   .map((value) => ({
            //     value,
            //     label: value === "1" ? "Einen Monat" : `${value} Monate`,
            //   }))}
            options={[
              {
                label: "Bestandskunde",
                value: "12",
              },
              {
                label: "Neukunde",
                value: stateFundingFactorDefault,
              },
            ]}
          /> */}

          {/* {!isMobile() && <div style={{ height: "var(--lineHeight)" }}></div>} */}

          {/* <RowEuro
            label={TEXT.price_annual_without_statefunding}
            euro={formatEuro(newPriceTotal)}
            prefix={TEXT.ca}
            // positive={stateFundingTotal > 0}
          /> */}
          <RowEuro
            label={TEXT.price_annual_with_statefunding}
            euro={formatEuro(priceTotal)}
            prefix={TEXT.ca}
            // positive={stateFundingTotal > 0}
          />
          <RowEuro
            label={TEXT.price_monthly_with_statefunding}
            euro={formatEuro(priceTotalMonthly)}
            prefix={TEXT.ca}
            // positive={stateFundingTotal > 0}
          />

          <RowEuro
            label={TEXT.state_funding_total}
            euro={formatEuro(stateFundingTotal)}
            prefix={TEXT.ca}
            positive={stateFundingTotal > 0}
            primary
          />
        </>
      </Cards>
    </div>
  );
}

const pick = (obj: any, keys: string[]) =>
  Object.fromEntries(
    keys.filter((key) => key in obj).map((key) => [key, obj[key]])
  );

function replaceObjectValues(
  obj: Record<string, string>,
  from: string,
  to: string
) {
  for (const key of Object.keys(obj)) {
    obj[key] = obj[key].replaceAll(from, to);
  }
}
