const urlParams = new URLSearchParams(window.location.search);

const cssColorVars = [
  "primaryColor",
  "primaryContrastColor",
  "positiveColor",
  "negativeColor",
  "secondaryColor",
  "backgroundColor",
  "cardBackgroundColor",
  "backgroundDimmColor",
  "borderColor",
  "textColor",
  "textColorMuted",
];

const cssPxVars = ["borderRadius", "textSize", "lineHeight", "padding"];

try {
  const r = document.documentElement;

  if (r) {
    for (const varName of cssColorVars) {
      const value = urlParams.get(varName);

      if (value) {
        r.style.setProperty("--" + varName, "#" + value);
      }
    }

    for (const varName of cssPxVars) {
      const value = urlParams.get(varName);

      if (value) {
        r.style.setProperty("--" + varName, value + "px");
      }
    }
  }
} catch (error) {
  console.error(error);
}
